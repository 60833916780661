export function getAgeFromBirthdate(birthdate: string): number {
  const today = new Date();
  const birthdateDate = new Date(birthdate);

  if (isValidDate(birthdateDate) === false) {
    return 18;
  }

  let age = today.getFullYear() - birthdateDate.getFullYear();
  const month = today.getMonth() - birthdateDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthdateDate.getDate())) {
    age--;
  }

  return age;
}

function isValidDate(date: any): boolean {
  return date instanceof Date && isNaN(date.getTime()) === false;
}
